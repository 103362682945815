import { CounterLabel, TabNav } from '@primer/react';
import { Table } from 'components';

import { CoreContext } from 'contexts';
import { DocumentData } from 'firebase/firestore';
import { Layout } from 'layouts';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { coreService, resultsService, studentsService } from 'services';
import './index.css';
import { BadgesSection, Behavioral, Parents } from './sections';
import { Result } from 'services/results/index.model';

const tabs = [
  { label: 'Results', value: 'results', disabled: false },
  { label: 'Badges', value: 'badges', disabled: false },
  { label: 'Behavioral Notes', value: 'behavioral', disabled: false },
  { label: 'Attendance', value: 'attendance', disabled: true },
  { label: 'Medical', value: 'medical', disabled: true },
  {
    label: 'Parents',
    value: 'parents',
    disabled: true
  }
];

const Student = () => {
  const { showLineLoader, setShowLineLoader, examList } = useContext(CoreContext);
  const params = useParams();
  const navigate = useNavigate();

  const [student, setStudent] = useState<DocumentData | null>(null);
  const [activeTab, setActiveTab] = useState('results');
  const [results, setResults] = useState<Result[] | null>(null);
  const [terms, setTerms] = useState<number[] | string[]>([]);
  const [subjects, setSubjects] = useState<{ id: string; name: string }[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<{
    year?: number | string;
    term?: number | string;
    subject?: string;
    exam_id?: string;
    order_by?: string;
    direction?: 'asc' | 'desc';
  }>({
    direction: 'desc'
  });
  const [fetchingResults, setFetchingResults] = useState(true);
  const [pagination, setPagination] = useState({
    count: 0,
    pages: 0
  });
  const [paginate, setPaginate] = useState({
    page: 1,
    size: 20
  });
  const [exams, setExams] = useState<{ id: string; name: string }[]>([]);
  const [orderBy] = useState<{ id: string; name: string }[]>([
    {
      id: 'all',
      name: 'Select'
    },
    {
      id: 'exam_score',
      name: 'Exam Score'
    },
    {
      id: 'final_score',
      name: 'Final Score'
    },
    {
      id: 'score',
      name: 'Score'
    },

    {
      id: 'remark_score',
      name: 'Remark Score'
    }
  ]);

  useEffect(() => {
    if (examList.length) {
      setExams([
        {
          id: 'all',
          name: 'All'
        },
        ...examList.map(exam => ({
          id: exam.id,
          name: exam.name
        }))
      ]);
    }
  }, [examList]);

  useEffect(() => {
    (async () => {
      try {
        setShowLineLoader(true);
        const id = params.id;
        if (id)
          await studentsService.getStudent(id, student => {
            setStudent(student);
          });
      } catch (error) {
        if (error instanceof Error) toast.error(error.message);
        setShowLineLoader(false);
      }
    })();
  }, [params, setShowLineLoader, navigate]);

  useEffect(() => {
    (async () => {
      try {
        if (student) {
          const subjects = student?.class?.subjects ?? [];
          setFetchingResults(true);
          await resultsService.getStudentResults(
            {
              admission_no: student?.admission_no,
              school_id: student?.school_id,
              year: new Date().getFullYear(),
              page: 1,
              size: 20
            },
            results => {
              setTerms(['All', '1', '2', '3']);
              setSubjects([
                {
                  id: 'all',
                  name: 'All'
                },
                ...subjects
              ]);
              setResults(results.data);
              setPagination(results.pagination);
              setShowLineLoader(false);
              setFetchingResults(false);
              setTimeout(() => {
                setFetchingResults(false);
              }, 2500);
            }
          );
        }
      } catch (error) {
        if (error instanceof Error) toast.error(error.message);
        setShowLineLoader(false);
        setFetchingResults(false);
      }
    })();
  }, [setShowLineLoader, student]);

  return (
    <Layout
      back={{
        label: 'Back to class',
        link: `/schools/${student?.school_id}/${student?.class_id}`
      }}
      className="student"
    >
      <section className="student-content">
        <section className="student-info-profile">
          <div
            className="student-info-avatar"
            style={{
              backgroundColor: showLineLoader ? '#f5f5f5' : ''
            }}
          >
            {!showLineLoader && student?.first_name && `${student?.first_name?.charAt(0)?.toUpperCase() ?? 'S'}`}
          </div>
          {!showLineLoader && student?.first_name && (
            <div className="student-info">
              <div className="student-info-title">
                {student?.first_name} {student?.last_name}
              </div>
              <div className="student-info-flex">
                <div className="student-info-flex-left">
                  <div className="student-info-list-item">
                    <div className="student-info-admission-label">Admission :</div>
                    <div className="student-info-admission-value">
                      {student?.admission_no} / {student?.class?.name}
                    </div>
                  </div>
                  <div className="student-info-list-item">
                    <div className="student-info-admission-label">Admission Date :</div>
                    <div className="student-info-admission-value">{student?.admission_date?.short_date}</div>
                  </div>
                </div>
                <div className="student-info-flex-right">
                  <div className="student-info-list-item">
                    <div className="student-info-admission-label">Fee Balance :</div>
                    <div className="student-info-admission-value">
                      <span>{student?.fees?.currency}</span>
                      <span style={{ marginLeft: 3 }}>{student?.fees?.balance}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
        <div className="student-info">
          <TabNav className="student-tab" aria-label="Main">
            {tabs.map(tab => (
              <TabNav.Link
                style={{
                  fontSize: activeTab === tab.value ? '1rem' : '0.9rem',
                  fontWeight: activeTab === tab.value ? 500 : 400,
                  opacity: tab.disabled ? 0.5 : 1
                }}
                key={tab.value}
                onClick={async () => {
                  try {
                    if (!tab.disabled) setActiveTab(tab.value);
                    if (tab.value === 'results') {
                      setFetchingResults(true);
                      await resultsService.getStudentResults(
                        {
                          admission_no: student?.admission_no,
                          school_id: student?.school_id,
                          year: new Date().getFullYear()
                        },
                        results => {
                          setResults(results.data);
                          setFetchingResults(false);
                        }
                      );
                    }
                  } catch (error) {
                    if (error instanceof Error) toast.error(error.message);
                    setFetchingResults(false);
                  }
                }}
                className="student-tab-item"
                selected={activeTab === tab.value}
              >
                {tab.label}
              </TabNav.Link>
            ))}
          </TabNav>
          {activeTab === 'results' && (
            <section className="student-info-results">
              <div className="student-info-label">Filter results</div>
              <div className="select-filters">
                <span className="select-filters-label">Year</span>
                <select
                  className="filters-select"
                  defaultValue={new Date().getFullYear()}
                  onChange={async e => {
                    try {
                      setFetchingResults(true);
                      const filters = { ...selectedFilters, year: e.target.value };
                      setSelectedFilters(filters);
                      setShowLineLoader(true);
                      await resultsService.getStudentResults(
                        {
                          ...filters,
                          admission_no: student?.admission_no,
                          school_id: student?.school_id
                        },
                        results => {
                          setResults(results.data);
                          setFetchingResults(false);
                        }
                      );
                      setShowLineLoader(false)
                    } catch (error) {
                      if (error instanceof Error) toast.error(error.message);
                      setFetchingResults(false);
                    }
                  }}
                >
                  {[...coreService.getYearsFromNow(10)].map(year => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                <span className="select-filters-label">Term</span>
                <select
                  className="filters-select"
                  onChange={async event => {
                    event.preventDefault();
                    try {
                      setFetchingResults(true);
                      const filters = { ...selectedFilters, term: event.target.value };
                      setSelectedFilters(filters);
                      setShowLineLoader(true);
                      await resultsService.getStudentResults(
                        {
                          ...filters,
                          admission_no: student?.admission_no,
                          school_id: student?.school_id
                        },
                        results => {
                          setResults(results.data);
                          setFetchingResults(false);
                        }
                      );
                      setShowLineLoader(false);
                    } catch (error) {
                      if (error instanceof Error) toast.error(error.message);
                      setFetchingResults(false);
                    }
                  }}
                >
                  {terms.map(term => (
                    <option key={term} value={term.toLocaleString().toLowerCase()}>
                      {term}
                    </option>
                  ))}
                </select>
                <span className="select-filters-label">Type</span>
                <select
                  className="filters-select"
                  onChange={async event => {
                    event.preventDefault();
                    try {
                      setFetchingResults(true);
                      const filters = { ...selectedFilters, type: event.target.value };
                      setSelectedFilters(filters);
                      setShowLineLoader(true);
                      await resultsService.getStudentResults(
                        {
                          ...filters,
                          admission_no: student?.admission_no,
                          school_id: student?.school_id
                        },
                        results => {
                          setResults(results.data);
                          setFetchingResults(false);
                        }
                      );
                      setShowLineLoader(false);
                    } catch (error) {
                      if (error instanceof Error) toast.error(error.message);
                      setFetchingResults(false);
                    }
                  }}
                >
                  {[
                    {
                      id: 'all',
                      name: 'All'
                    },
                    {
                      id: 'graded',
                      name: 'Graded'
                    },
                    {
                      id: 'naration',
                      name: 'Narration'
                    }
                  ].map(type => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </select>
                <span className="select-filters-label">Subject</span>
                <select
                  className="filters-select"
                  onChange={async event => {
                    event.preventDefault();
                    try {
                      setFetchingResults(true);
                      const filters = { ...selectedFilters, subject: event.target.value };
                      setSelectedFilters(filters);
                      setShowLineLoader(true)
                      await resultsService.getStudentResults(
                        {
                          ...filters,
                          admission_no: student?.admission_no,
                          school_id: student?.school_id
                        },
                        results => {
                          setResults(results.data);
                          setFetchingResults(false);
                        }
                      );
                      setShowLineLoader(false);
                    } catch (error) {
                      if (error instanceof Error) toast.error(error.message);
                      setFetchingResults(false);
                    }
                  }}
                >
                  {subjects.map(subject => (
                    <option key={subject.id} value={subject.id}>
                      {subject.name}
                    </option>
                  ))}
                </select>
                <span className="select-filters-label">Exam</span>
                <select
                  className="filters-select"
                  onChange={async event => {
                    event.preventDefault();
                    try {
                      setFetchingResults(true);
                      const filters = { ...selectedFilters, exam_id: event.target.value };
                      setSelectedFilters(filters);
                      setShowLineLoader(true)
                      await resultsService.getStudentResults(
                        {
                          ...filters,
                          admission_no: student?.admission_no,
                          school_id: student?.school_id
                        },
                        results => {
                          setResults(results.data);
                          setFetchingResults(false);
                        }
                      );
                      setShowLineLoader(false)
                    } catch (error) {
                      if (error instanceof Error) toast.error(error.message);
                      setFetchingResults(false);
                    }
                  }}
                >
                  {exams.map(exam => (
                    <option key={exam.id} value={exam.id}>
                      {exam.name}
                    </option>
                  ))}
                </select>
                <span className="select-filters-label">Order By</span>
                <select
                  className="filters-select"
                  onChange={async event => {
                    event.preventDefault();
                    try {
                      setFetchingResults(true);
                      const filters = { ...selectedFilters, order_by: event.target.value };
                      setSelectedFilters(filters);
                      setShowLineLoader(true);
                      await resultsService.getStudentResults(
                        {
                          ...filters,
                          admission_no: student?.admission_no,
                          school_id: student?.school_id
                        },
                        results => {
                          setResults(results.data);
                          setFetchingResults(false);
                        }
                      );
                      setShowLineLoader(false);
                    } catch (error) {
                      if (error instanceof Error) toast.error(error.message);
                      setFetchingResults(false);
                    }
                  }}
                >
                  {orderBy.map(order => (
                    <option key={order.id} value={order.id}>
                      {order.name}
                    </option>
                  ))}
                </select>
                <span className="select-filters-label">Direction</span>
                <select
                  className="filters-select"
                  onChange={async event => {
                    event.preventDefault();
                    try {
                      setFetchingResults(true);
                      const filters = { ...selectedFilters, direction: event.target.value as 'asc' | 'desc' };
                      setSelectedFilters(filters);
                      setShowLineLoader(true);
                      await resultsService.getStudentResults(
                        {
                          ...filters,
                          admission_no: student?.admission_no,
                          school_id: student?.school_id
                        },
                        results => {
                          setResults(results.data);
                          setFetchingResults(false);
                        }
                      );
                      setShowLineLoader(false)
                    } catch (error) {
                      if (error instanceof Error) toast.error(error.message);
                      setFetchingResults(false);
                    }
                  }}
                >
                  <option value="asc">Ascending</option>
                  <option value="desc">Descending</option>
                </select>
              </div>
              <Table
                className="student-info-results-table"
                itemsLabel="Results"
                totalItems={pagination.count}
                emptyText={fetchingResults ? 'Please wait...' : 'No results found'}
                pagination={{
                  show: true,
                  options: {
                    sizes: [10, 20, 25, 50, 100],
                    pages: pagination.pages,
                    currentPage: paginate.page,
                    handlePageChange: async data => {
                      try {
                        setPaginate({ ...paginate, page: data.page });
                        await resultsService.getStudentResults(
                          {
                            admission_no: student?.admission_no,
                            school_id: student?.school_id,
                            year: new Date().getFullYear(),
                            page: data.page,
                            size: data.size
                          },
                          results => {
                            setResults(results.data);
                            setFetchingResults(false);
                          }
                        );
                      } catch (error) {
                        if (error instanceof Error) toast.error(error.message);
                        setFetchingResults(false);
                      }
                    }
                  }
                }}
                header={[
                  {
                    label: 'Course'
                  },
                  {
                    label: 'Exam'
                  },
                  {
                    label: 'Exam Score'
                  },
                  {
                    label: 'Score (Total %)'
                  },
                  {
                    label: 'Weighted (Total %)'
                  },
                  {
                    label: 'Term'
                  },
                  {
                    label: 'Remarks'
                  }
                ]}
                data={results?.map(result => (
                  <tr key={result.id}>
                    <td style={{ textTransform: 'capitalize' }}>
                      {result.subject.includes('-') ? result.subject.split('-') : result.subject}
                    </td>
                    <td>{result.exam}</td>
                    <td>
                      {result.score} / {result.total_marks}
                    </td>
                    <td>{result.exam_score ?? 0}%</td>
                    <td>{result.final_score ?? 0}%</td>
                    <td>{result.term}</td>
                    <td>
                      <span>{result.remark}</span>
                      <CounterLabel
                        scheme="primary"
                        style={{
                          marginLeft: 5,
                          backgroundColor:
                            result.remark_score >= 4
                              ? 'var(--success-500)'
                              : result.remark_score >= 3
                              ? 'var(--warning-500)'
                              : 'var(--danger-500)'
                        }}
                      >
                        {result.remark_score}
                      </CounterLabel>
                    </td>
                  </tr>
                ))}
              />
            </section>
          )}
          {activeTab === 'badges' && (
            <BadgesSection
              student={{
                student_id: student?.id,
                class_id: student?.class_id
              }}
            />
          )}
          {activeTab === 'parents' && <Parents data={student?.parents ?? []} />}
          {activeTab === 'behavioral' && (
            <Behavioral
              student={{
                student_id: student?.id,
                class_id: student?.class_id,
                year: new Date().getFullYear()
              }}
              data={student?.behavioral_notes ?? []}
            />
          )}
        </div>
      </section>
    </Layout>
  );
};

export default Student;
